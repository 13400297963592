import React from "react";

import { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useEvent } from "effector-react/scope";

import { t, Trans } from "@lingui/macro";

import { IconButton } from "@chakra-ui/button";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { CircularProgress, useDisclosure, useToast } from "@chakra-ui/react";

import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";

import ModalConfirmation from "components/modals/confirmation";
import ModalProcessingDetails from "components/modals/processing-details";

import wizardModel from "models/wizard";

import { calculateProcessingCost, deleteProcessing } from "shared/api/client";
import { APIError } from "shared/lib/errors";

import { Processing, ProcessingCost, ProcessingPayload } from "types";

import { ShareMenu } from "./share-menu";

export type CardMenuProps = {
  processing: Processing;
};

const CardMenu: React.VFC<CardMenuProps> = ({ processing }) => {
  const queryClient = useQueryClient();

  const copyProcessingConfig = useEvent(
    wizardModel.events.copyProcessingConfig
  );

  const modals = {
    details: useDisclosure(),
    delete: useDisclosure(),
    // edit: useDisclosure(),
  };

  const toast = useToast();

  const { mutate: validateJobConfig } = useMutation<
    AxiosResponse<ProcessingCost>,
    APIError,
    ProcessingPayload
  >(calculateProcessingCost, {
    onSuccess: async () => {
      copyProcessingConfig({
        ...processing.config,
        name: processing.name,
      });

      toast({
        title: t`Сonfig copied`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t`Processing config invalid`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const { mutate: handleDelete, isLoading } = useMutation(deleteProcessing, {
    onSuccess: () => {
      queryClient.invalidateQueries(["processings", processing.projectId]);
      toast({
        title: t`Processing deleted`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t`Error delete processing`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // const defaultValues = useMemo(
  //   () => ({
  //     name: processing.name,
  //     description: processing.description,
  //   }),
  //   [processing.name, processing.description]
  // );

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          color="blue.500"
          variant="ghost"
          disabled={isLoading}
          icon={
            isLoading ? (
              <CircularProgress size="0.875rem" capIsRound isIndeterminate />
            ) : (
              <BsThreeDots />
            )
          }
          size="sm"
          aria-label="more..."
        />
        <MenuList zIndex={100}>
          {/* <MenuItem fontSize="0.875rem" onClick={modals.edit.onOpen}>
            <Trans>Edit</Trans>
          </MenuItem> */}
          <MenuItem
            fontSize="0.875rem"
            onClick={() =>
              validateJobConfig({
                projectId: processing.projectId,
                name: processing.name,
                aoi: processing.config.aoi,
                source: processing.config.source,
                model: processing.config.modelId,
                modelOptionsIds: processing.config.modelOptionIds,
              })
            }
          >
            <Trans>Duplicate</Trans>
          </MenuItem>
          <MenuItem fontSize="0.875rem" onClick={modals.details.onOpen}>
            <Trans>Source details</Trans>
          </MenuItem>
          <MenuItem fontSize="0.875rem" onClick={modals.delete.onOpen}>
            <Trans>Delete</Trans>
          </MenuItem>
          <MenuDivider />
          <ShareMenu processing={processing} />
        </MenuList>
      </Menu>
      <ModalProcessingDetails
        isOpen={modals.details.isOpen}
        onClose={modals.details.onClose}
        isCentered
        config={processing.config}
        processingId={processing.id}
        rasterLayer={processing.rasterLayer}
      />

      <ModalConfirmation
        isCentered
        isOpen={modals.delete.isOpen}
        onClose={modals.delete.onClose}
        onConfirm={() => {
          handleDelete(processing.id);
          modals.delete.onClose();
        }}
        title={t`Delete processing?`}
        description={t`Are you sure you want to delete this processing?`}
        cancelText={t`Cancel`}
        confirmText={t`Delete`}
      />
      {/* <ModalProcessingEdit
        isOpen={modals.edit.isOpen}
        onClose={() => {
          modals.edit.onClose();
          updateMutation.reset();
        }}
        defaultValues={defaultValues}
        error={updateMutation.error}
        isLoading={updateMutation.isLoading}
        onConfirm={updateMutation.mutate}
      /> */}
    </>
  );
};

export default CardMenu;
