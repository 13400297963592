import { Text } from "@chakra-ui/react";

import { AnimatePresence, motion } from "framer-motion";

type Props = {
  isOpen: boolean;
  isHasMessage: boolean;
  message: string;
};

export const ErrorCollapse: React.VFC<Props> = ({
  isOpen,
  message,
  isHasMessage,
}) => {
  return (
    <AnimatePresence>
      {isHasMessage && isOpen && (
        <motion.div
          initial="close"
          animate="open"
          exit="close"
          style={{
            marginTop: "4px",
            marginLeft: "2px",
            borderLeft: "2px solid #a66969",
          }}
          variants={{
            open: {
              opacity: 1,
              height: "fit-content",
              y: 0,
            },
            close: { opacity: 0, height: 0, y: -6 },
          }}
        >
          <Text mt={1} ml={1} fontSize="13px">
            {message}
          </Text>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
